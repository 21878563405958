<template>
  <div>
    <div class="title-tabs">
      <b-row calss="">
        <b-col md="6" class="text-left d-flex">
          <b-form-checkbox class="" id="Template" name="Template">
          </b-form-checkbox>
          <div>Template</div></b-col
        >
        <b-col md="6" class="justify-content-end d-flex">
          <div class="mr-1 text-link cursor-pointer">Save</div>
          <div>|</div>
          <div class="ml-1 text-link cursor-pointer">Cancel</div>
        </b-col>
      </b-row>
    </div>
    <div class="p-3">
      <b-row>
        <b-col md="6">
          <div>
            <InputSelect
              title="Template"
              :options="templateOptions"
              valueField="value"
              textField="text"
              v-model="form.template"
              placeholder="Template"
            >
            </InputSelect>
          </div>
          <div>
            <UploadFile
              v-model="form.image_url"
              :value="form.image_url"
              textFloat="Image"
              placeholder="Please Select a File To Upload."
              format="image"
              name="thumbnail"
              accept="image/jpeg, image/png"
              text="*Please upload only file .png, .jpg ratio more than 1:45 and size less than 1 MB."
              v-on:onFileChange="onImageChange"
              isRequired
              :fileName="form.image_url"
              :maxSize="1000000"
            />
          </div>
          <div class="preview-img-container d-flex " >
       
            <PreviewBox
                  v-on:delete="deleteImage"
                  :disabled="isDisable"
                  :showPreviewType="0"
                  :showPreview="showPreview"
                  :isLoadingImage="isLoadingImage"
                  :ratioType="1"
                  class="previewBox"
                />
                <div>
                  <li class="mt-1">Image Format JPEG or PNG</li>
                  <li class="mt-1">Image width: 800 to 2500 pixels</li>
                  <li class="mt-1">Image height: 250 pixels or more</li>
                  <li class="mt-1">Image aspext ratio (width/height): 1.45 or more</li>
                  <li class="mt-1">Max file size: 1 MB</li>
                </div>
          </div>
        </b-col>
        <b-col cols="12" lg="8" xl="6">
          <div class="mb-1">Preview</div>
              <div class="preview-container">
                <div class="wrap-panel"> 
                  <div class="layout">
                    <template>
                      <!-- <div class="navbar">
                        <div>
                          <img
                            :src="
                              $store.state.theme.themeLogo.themeLogoDomain +
                              $store.state.theme.themeLogo.result.themeLogoLine
                            "
                            class="logo-img"
                          />
                        </div>
                      </div>

                      <ProfileDisplay
                        :layout="form.line_layout"
                        :displayMemberLevel="form.line_display_member_level"
                        :displayLogout="form.line_display_logout_button"
                        :displayPoint="form.line_display_point_channel"
                        :displayConnect="form.display_connect_channel"
                      /> -->
                    </template>
                  </div>
                </div>
              </div>
            </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import UploadFile from "@/components/inputs/UploadFile";

import PreviewBox from "@/components/PreviewBox.vue";

export default {
  components: {
    InputText,
    InputSelect,
    UploadFile,
    PreviewBox
  },
  props: {
    form: {
      required: "true",
      type: Object,
    },
  },

  data() {
    return {
      showPreview: this.form.image_url,
      isLoadingImage: false,
      templateOptions: [{ text: "3*2", value: "3*2" }],
      isDisable:false,
    };
  },
  validations() {
    return {
      form: {},
    };
  },
  async created() {},
  methods: {
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        let img = new Image();

        img.src = reader.result;

        img.onload = async () => {
          const ratio = img.width / img.height;
          console.log(ratio);
          if (ratio < 1.45) {
            this.warningAlert(
              "Please upload an image with a ratio equal to or more than 1:45 !"
            );
            this.isLoadingImage = false;
            this.isDisable = false;
          } else if (img.height < 250) {
            this.warningAlert(
              "Please upload an image with a height of at least 250 pixels."
            );
            this.isLoadingImage = false;
            this.isDisable = false;
          } else if (img.width < 800 || img.width > 2500) {
            this.warningAlert(
              "Please upload an image with a width equal to 800 to 2500 pixels."
            );
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.images = await this.saveImagetoDb(reader.result);
            this.isLoadingImage = false;
            this.isDisable = false;
            this.form.image_url = this.images;
            this.showPreview = this.form.image_url;
          }
        };
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
        is_video: false,
        type: 0,
      };
      await this.$store.dispatch("uploadProductImage", form);
      var data = await this.$store.state.product.stateUploadProductImage;

      if (data.result == 1) {
        return data.detail;
      }
    },
    deleteImage() {
      this.form.product.image_url = null;
      this.showPreview = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
.border-preview {
  border: 2px dashed #979797;
  padding: 2rem;
}
.wrap-panel {
  position: relative;
  width: 70%;
  max-width: 370px;
}
.layout {
  width: 100%;
  /* border-radius: 0.75rem; */
  position: relative;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 9 / 19.5;
  /* 
  background: gray; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: whitesmoke;
  /* justify-content: space-between; */
}
.preview-container {
  display: flex;
  justify-content: center;
  /* border: 1px dashed whitesmoke; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='5' stroke-dasharray='4%2c15' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 0.75rem;
}
.navbar {
  height: 80px;
  width: 100%;
  justify-content: center;
  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}

.previewBox{

  width: 40%;
}

</style>
